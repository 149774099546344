.page-template-thnk-you {
    .hero {
        padding: 5rem 0 4rem 0;
        background-image: url(../../assets/images/thankyou-bg.jpeg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
    }

    ol {
        font-size: 1.5rem;
        padding: 0 0 0 1.5rem;

        li {
            margin-bottom: 1rem;
        }
    }

    .flyer {
        padding: 1rem;
        background: $light-grey3;
        border-radius: $border-radius;
        margin: 0 0 2rem 0;
    }
}
