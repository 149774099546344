//colors
$black: #00142a;
$white: #fff;

$dark-grey: #171719;
$grey: #8F8F8F;
$grey2: #555555;
$light-grey: #efefef;
$light-grey2: #666;
$light-grey3: #f9f9f9;

$red: #DE000D;
$magenta: #e44ba3;

$pink: #e56d9e;
$dark-pink: #d35b8c;
$light-pink: #f2b6ce;
$light-pink2: #F8DDE9;

$green: #00B533;

$navy-blue: #044389;
$blue: #48b3e3;
$light-blue: #aeddfb;

$violet: #9872b5;
$violet2: #9652C9;

$font-color: $navy-blue;

$input-border: #B4C7DC;
$input-bg: $light-grey3;

//categories
$violet2: rgba($violet2, 0.5);
$green2: rgba($green, 0.5);
$blue2: rgba($blue, 0.5);

//calendar
$bright-blue: #0379fc;
$blue-gradient: linear-gradient(
    90deg,
    rgb(0, 64, 134) 0%,
    rgb(8, 8, 91) 65%,
    rgb(6, 6, 79) 100%
);

//env indicator
$dev-color: $blue;
$stage-color: $green;
$prod-color: $red;
// In italics “v” from “dev” sticks out from the container tad too much
$italicDevCompensation: 1.1rem;

$zIndexEnvIndicator: 16777271;
$zIndexCacheMessage: 1001;
$zIndexFloatingACtion: 1001;
$zIndexFooter: 1000; // .fixed-top (main menu) has 1030
$zIndexMenu: 100;
$zIndexTimeline: 5;
$zIndexLoader: 1;

$border-radius: 10px;
