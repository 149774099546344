@charset "UTF-8";

.page-template-diet-new {
    height: 100%;

    .add-remove-fav{
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -5px;
        margin-right: 10px;
        background: none;
        border: none;
        padding: 0;

        background-color: rgba($navy-blue, 0.3);
        border-radius: 50%;
        padding: 2px;

        width: 30px;
        height: 30px;

        @include media-breakpoint-up(md){
            margin-right: 25px;
        }

        img{
            max-width: calc(100% - 4px);
            margin-top: -4px;
            height: auto;
        }
    }

    .meal{
        position: relative;
        padding: 0 0 16px 0;

        .embed-responsive{
            position: relative;
            margin: -16px -16px 16px -16px;
            width: calc(100% + 32px);
            border-radius: 0;

            @include media-breakpoint-up(md){
                margin: -16px 0 -16px -16px;
                width: calc(100% + 16px);
                height: calc(100% + 32px);

                &::before{
                    padding-top: 0;
                }
            }

            .embed-responsive-item{
                background-size: cover;
                background-position: center;
            }
        }

        .meal-title{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 0 16px 0;
            min-height: 56px;

            img{
                margin-top: 3px;
            }
         
            .dietType {
                display: inline-block;
                border: 1px solid #efefef;
                border-radius: $border-radius;
                padding: 2px 10px;
                color: $navy-blue;
                font-weight: 500;
                font-size: 16px;
            }

        }
    }

    .training{
        padding: 0 0 16px 0;

        .training-content{
            border: 1px solid $navy-blue;
            border-radius: $border-radius;
            padding: 16px;

            @include media-breakpoint-up(md){
                padding: 20px;
            }

            h2{
                font-size: 20px;
                font-weight: 500;
                margin: 0 0 16px 0;
                line-height: 1.5;

                @include media-breakpoint-up(md){
                    font-size: 22px;
                }
            }
        }
    }

    .summary{
        font-size: 14px;
        color: $grey;

        .border-right {
            position: relative;
            border-right: none !important;

            &::before{
                content: '';
                width: 1px;
                height: 30px;
                background: #EFEFEF;
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -16px;
            }
        }
    }

    // meal view
    .meal-navigation{
        position: sticky;
        top: 0;
        background: $white;
        padding: 0;
        z-index: 10;
        box-shadow: 0px 4px 6px rgba($black, 0.1);
        overflow-x: auto;

        &.active {
            transition: all 0.4s ease-in-out;
        }
        
        &.hidden {
            top: 0;
            transition: all 0.4s ease-in-out;
        }

        .container{
            padding: 0;
        }

        ul{
            text-align: center;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none; // Safari and Chrome
            }

            @include media-breakpoint-up(sm) {
                overflow: hidden;
            }

            .nav-item{
                display: inline-block;
            }
                
        }

        .nav-link{
            padding: 12px 12px 10px 12px;
            color: $grey;
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            white-space: nowrap;
            cursor: pointer;
            border-bottom: 2px solid $white;

            @include media-breakpoint-up(md){
                padding: 12px 24px 10px 24px;
            }

            &:active, &:hover{
                color: $grey;
            }

            &.active {
                color: $pink;
                border-color: $pink;
            }

            // .embed-responsive{
            //     margin: 0 -15px;
            //     width: calc(100% + 30px);

            //     &.active{
            //         color: $pink;
            //         border-color: $pink;
            //     } 
            // } 
        }
    }
    
    //meal-details
    .meal-details {
        position: relative;
        padding-top: 0;

        @include media-breakpoint-up(md){
            padding-top: 1.5rem;
        }

        .embed-responsive{
            margin: 0 -15px;
            width: calc(100% + 30px);
            border-radius: 0;

            @include media-breakpoint-up(md){
                margin: 0;
                width: 100%;
                border-radius: 10px;
            }

            .video{
                background-color: $grey;
            }

            .embed-responsive-item{
                background-size: cover;
                background-position: center;
            }
        }

        .meal-info{
            position: relative;
            padding-top: 24px;
            font-size: 14x;
            height: 100%;

            @include media-breakpoint-up(md){
                font-size: 18px;
                padding-top: 0;
            }

            .action-buttons{
                position: sticky;
                top: 100%;
            }
        }

        .dish{
            border-top: 1px solid $light-grey;
            margin-top: 24px;
            padding-top: 32px;
            
            .col-md-5{
                border-top: 1px solid $light-grey;
                margin-top: 24px;
                padding-top: 32px;

                @include media-breakpoint-up(md){
                    margin-top: 0;    
                    padding-top: 0;
                    border-top: none;
                }
            }
        }

        .ingredients {
            font-size: 18px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                margin: 0 0 16px 0;
                padding-left: 24px;
                cursor: pointer;
            }
        }

        .btn-change {
            vertical-align: middle;
            display: inline-block;
            margin: -2px 8px 0 0;
            width: 20px;
            height: 20px;
            padding: 0;
            background: none;
            border: none;
            outline: none;

            .img-fluid {
                vertical-align: top;
            }
        }

        .collapse, .collapsing{
            margin-left: -24px;
        }

        .card {
            position: relative;
            margin-top: 16px;
            
            border-radius: 10px;
            border: none;
            background: rgba($pink, 0.15);
            z-index: 0;

            .card-body {
                padding: 16px;
                font-size: 16px;
                color: $primary;
                text-align: center;

                .ingredients {
                    text-align: left;
                    color: $font-color;
                    font-size: 16px;
                    margin: 0;

                    li{
                        padding-left: 24px;
                    }

                    button {
                        background: none;
                        border: none;
                        color: $font-color;
                    }
                }
            }
        }

        .recipe {
            ol {
                font-size: 16px;
                padding: 0 0 0 40px;
                list-style: none;
                counter-reset: recipeCounter;

                li {
                    position: relative;
                    counter-increment: recipeCounter;
                    margin-bottom: 16px;

                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }

                    &::before {
                        content: counter(recipeCounter);
                        position: absolute;
                        top: 0;
                        left: -40px;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background: $light-grey;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 600;
                        color: $font-color;
                        line-height: 28px;
                    }
                }
            }
        }

        footer{
            position: sticky;
            bottom: 0;
            width: 100%;
            padding: 8px 0 !important;
            background: $white;
            box-shadow: 0px -4px 6px rgba($black, 0.1);
            border-top: none;
        }
    }

    .change-meal{
        h3{
            font-size: 20px;
            color: $pink;
        }

        .meal-replacement{
            padding: 12px !important;
            font-size: 16px;

            &.favMeal{
                border: 1px solid $pink;
            }
            
            .embed-responsive{
                margin: 0 !important;
                width: 100% !important;
                height: 100% !important;
                border-radius: $border-radius;

                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                }
            }

            .col-8{
                height: 72px;
                padding-left: 0;
            }

            .col-9{
                max-height: 72px;
                overflow: hidden;

                // trim to 3 lines
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  
            }
        }
    }

    .change-day{
        padding-bottom: 1rem;
        
        h3{
            font-size: 20px;
            font-weight: 600;
        }

        input[type="search"]{
            background: $white;
        }

        hr{
            margin: 32px 0;
        }

        label{
            font-weight: 500;
        }

        .white-box{
            padding: 16px;

            &.holiday{
                border: 2px solid $pink;
            }
        }

        .form-control {
            height: auto;
            color: #555;

            background: #F9F9F9 !important;
            border: 1px solid #B4C7DC;
            border-radius: 10px;
        }

        .event-meal{
            border-radius: $border-radius;
            overflow: hidden;
            margin: 0 0 10px 0;
            font-size: 16px;
            padding: 0;

            .embed-responsive{
                margin: 0 !important;
                width: 100% !important;
                // height: 100% !important;
                border-radius: $border-radius;

                .embed-responsive-item{
                    background-size: cover;
                    background-position: center;
                }
            }

            .col-9{
                height: 76px;
                padding-left: 0;

                @include media-breakpoint-up(lg){
                    display: flex;
                    align-items: center;
                    height: 93px;
                }

                div{
                    max-height: 76px;
                    overflow: hidden;
                    padding-top: 4px;

                    // trim to 2 lines
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;  

                    @include media-breakpoint-up(md){
                        padding-top: 0;
                        max-height: 93px;
                    }
                }
            }
        }

        .list-of-dishes{
            margin: 0;
            padding: 0;
            list-style: none;
            display: inline;

            li{
                display: inline;
            }
        }
    }

    .done-icon{
        position: absolute;
        top: 6px;
        left: 20px;
    }
}

.modal-dialog.favourites{
    .modal-content{
        border-radius: 10px;
        border: none;
        text-align: center;

        .modal-header{
            border-bottom: none;
            padding: 20px 20px 10px 20px;
        }

        .modal-body{
            padding: 0 20px;
        }

        .modal-footer{
            border-top: none;
            justify-content: center;
            padding: 20px;
        }
    }
}
