@import 'variables';

//overwritem bootstrap vars
// $grid-breakpoints: (
//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
// );

// $container-max-widths: (
//     sm: 540px,
//     md: 720px,
//     lg: 960px,
// );

// $input-focus-border-color: lighten(#48b3e3, 25%);
// $input-btn-focus-color: rgba(#48b3e3, 0.25);

// $modal-content-border-radius: 0;

//bootstrap
@import '~bootstrap/scss/bootstrap';
//typekit fonts
@import url("https://use.typekit.net/odk5snz.css");
//local fonts
@import 'typeface';

//functions
@import 'functions/rem-calc';

//vendor
@import 'vendor/snackbar';

//core stylesheet
@import 'layout';
@import 'navigation';
@import 'typography';
@import 'button';
@import 'form';
@import 'footer';
@import 'flashMessage';
@import 'alerts';
@import 'table';


//plugins
@import 'plugins/timeline';
@import 'plugins/chartist';
@import 'plugins/odometer';

//components
@import 'components/chart';
@import 'components/floating-action-button';
@import 'components/calendar';
@import 'components/cachedDataMessage';
@import 'components/envIndicator';
@import 'components/developerOptions';

//page templates
@import 'pageTemplates/diet-new';
@import 'pageTemplates/shoppingList';
@import 'pageTemplates/activities';

@import 'pageTemplates/favMeals';

@import 'pageTemplates/articles';
@import 'pageTemplates/article';
@import 'pageTemplates/hydration';
@import 'pageTemplates/mindfulness';
@import 'pageTemplates/dietSettings';
@import 'pageTemplates/login';

// @import 'pageTemplates/diet';
// @import 'pageTemplates/login';
// @import 'pageTemplates/infoPages';
// @import 'pageTemplates/cmsContent';
// @import 'pageTemplates/error';
// @import 'pageTemplates/authConfirm';

// @import 'pageTemplates/promoCode';
// @import 'pageTemplates/quiz';
// @import 'pageTemplates/coupon';
// @import 'pageTemplates/newsletter-unsubscribe';

@import 'pageTemplates/cart';
@import 'pageTemplates/priceList';
@import 'pageTemplates/thankYou';


// @import 'pageTemplates/content';

//utils

