.page-template-cart {
    main{
        background: $light-grey3;

        section {
            padding: 0;
            border-bottom: none;
        }

        .line{
            position: relative;
            text-align: center;
            margin: 1rem 0;
            font-size: 14px;
            color: $grey;
            
            span{
                position: relative;
                display: inline-block;
                background: $white;
                padding: 0 1rem;
            }

            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background: $light-grey2;
                top: 50%;
                left: 0;
            }
        }
        
        .product-thumbnail{
            width: 100px;
            height: auto;
        }
    
        .price {
            text-align: right;
        }
    
        .payment-method-form {
            .collapse.show {
                display: inline;
            }
        }
    
        span.collapse-arrow {
            display: inline-block;
            color: $primary;
            transition: all 0.3s ease-in-out;
    
            &.open {
                transform: rotate(180deg);
            }
        }
    
        .cart-product {
            .product-row {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 18px;
                color: $grey;

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                }

                
            }
        }
    
        .cart-summary {
            .row {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 18px;
                color: $grey;
    
                &.delivery-option-price {
                    border-bottom: 1px solid $primary;
                }

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                    color: $navy-blue;    
                }
            }

            .summary-product {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 18px;
                color: $grey;

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                }
            }
        }
    
        .payment-method {
            p {
                font-size: 14px;
                color: $grey;
            }
    
            .payment-logo {
                max-height: 55px;
            }
        }
    }
}
