button {
    border: none;
    background: none;
    
    &.bright-blue {
        color: $bright-blue;
    }

    &.pink {
        color: $pink;
    }
}

//reactstrap buttons styles
.btn{
    position: relative;
    padding: rem-calc(5) rem-calc(30);
    font-size: rem-calc(18);
    line-height: 1.25;
    font-weight: 500;
    border-radius: rem-calc(36);

    &:focus{
        box-shadow: none;
    }

    &.btn-lg{
        padding: rem-calc(9) rem-calc(30);
        font-size: rem-calc(20);
    }

    &.btn-sm{
        // padding: rem-calc(8) rem-calc(40);
        padding: rem-calc(8) rem-calc(20);
        font-size: rem-calc(14);
    }

    &.btn-primary{
        color: $white;
        background-color: $pink;
        border: 2px solid $pink;
        max-width: 300px;

        &:disabled{
            color: rgba($white, 0.5);
            background-color: $light-pink;
            border-color: $light-pink;
        }

        &:hover, &:active{
            background-color: $dark-pink !important;
            border-color: $dark-pink !important;
            box-shadow: none;
        }

        &:focus{
            box-shadow: none !important;
        }
    }

    &.btn-outline-primary{
        border: 2px solid $pink;
        color: $pink;
        max-width: 300px;
        
        &:hover, &:active{
            background: none;
            border-color: $pink;
        }
        
        &:focus{
            background: none;
            box-shadow: none;
        }    
        
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active{
            color: $white;
            background-color: $pink;
            border-color: $pink;
            box-shadow: none;
        }
    }

    &.btn-social{
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        border-radius: 20px;
        padding: 6px 10px 6px 36px;
        display: block;
        margin: 0.5rem auto;
        font-family: Arial, Helvetica, sans-serif;
        text-decoration: none;
        max-width: 300px;
    }

    &.facebook{
        background: #4267b2;
        color: $white;
        border: 1px solid #4267b2;
        background-image: url(../assets/icons/button_facebook_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }

    &.google{
        background: $white;
        color: $black;
        border: 1px solid $black; 
        background-image: url(../assets/icons/button_google_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }

    &.apple{
        background: $black;
        color: $white;
        border: 1px solid $black;
        background-image: url(../assets/icons/button_apple_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }

    &.btn-link{
        color: $pink;
        font-weight: 400;
        text-decoration: underline;
    }

    &.btn-arrow{
        position: relative;
        padding: 19px 12px;
        color: $black;
        background-color: $white;
        font-weight: 300;
        border-radius: $border-radius;
        box-shadow: 0px 4px 8px 1px rgba($black, 0.1);
        text-align: left;

        .SVGInline {
            color: $navy-blue;
            float: right;
        }
    }

    .spinner-border {
        position: absolute;
        top: 10px;
        left: 20px;
        width: 12px;
        height: 12px;

        &.spinner-border-sm {
            border-width: 0.15em;
        }
    }
}

.btn-group {
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 20rem;
    }

    .btn-secondary {
        background: transparent;
        color: $blue;
        border: 2px solid $blue;

        &.active {
            color: $white;
            background-color: $blue !important;
            border-color: $blue !important;

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($blue, 0.25) !important;
            }

            &::after {
                display: none;
            }
        }
    }
}
