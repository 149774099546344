@import "~react-lazy-load-image-component/src/effects/blur.css";

.page-template-article-list {
    header {
        padding: 0 0 2rem 0;

        @include media-breakpoint-up(md) {
            padding: 1rem 0;
        }

        h1 {
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 0;
            text-align: center;
        }
    }

    .categoryFilter {
        border-bottom: 1px solid #efefef;
        border-top: 1px solid #efefef;

        .nav-pills {
            text-align: center;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none; // Safari and Chrome
            }    

            .nav-item {
                display: inline-block;
                &.active {
                    border-bottom: 2px solid $pink;
                    a {
                        color: $pink;
                    }
                }
                a {
                    display: inline-block;
                    padding: 1rem;
                    color: $grey;
                    font-size: 16px;
                    @include media-breakpoint-up(sm) {
                        font-size: 18px;
                    }
                }
            }

            .nav-item:hover {
                a {
                    text-decoration: none;
                }
            }
        }
    }

    h2 {
        margin: 0 0 1rem 0;
        font-size: 1.5rem;
    }

    p,
    span {
        font-size: 16px;
        color: $font-color;
    }

    .my-masonry-grid {
        display: flex;
        margin-left: -30px; /* gutter size offset */
        width: auto;

        .my-masonry-grid_column {
            padding-left: 30px; /* gutter size */
            background-clip: padding-box;

            .card {
                // display: grid;
                margin-bottom: 30px;
                position: relative;
                text-decoration: none;
                border-radius: $border-radius;
                overflow: hidden;
                max-width: 100%;
                transition: all 0.3s ease-in-out;
                box-shadow: 0px 4px 6px rgba($black, 0.1);

                &.border-primary {
                    border-width: 2px;
                }

                &.placeholder {
                    height: 20rem;
                    cursor: default;
                }

                .thumbImg {
                    position: relative;
                    overflow: hidden;
                }

                .card-body {
                    padding: 1.2rem 1.2rem 1.3rem 1.2rem;

                    .category {
                        font-size: 14px;
                        display: inline;
                        padding: 3px 12px;
                        border-radius: 8px;
                        color: $white;

                        &.diet {
                            background-color: $green2;
                        }

                        &.motivation {
                            background-color: $violet2;
                        }

                        &.tips {
                            background-color: $blue2;
                        }

                        &.excercise {
                            background-color: rgba($navy-blue, 0.5);
                        }
                    }
                    h2 {
                        font-size: 24px;
                        font-weight: 700;
                        padding: 1rem 0;
                        margin: 0;
                    }

                    .article-teaser {
                        span {
                            color: $font-color;
                        }

                        a {
                            text-decoration: none;
                        }

                        .read-more {
                            padding: 10px 0 0 0;
                            margin-bottom: 0;
                            color: $pink;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
