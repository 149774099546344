h1, .heading-1 {
    color: $font-color;
    font-size: rem-calc(36);
    font-weight: 500;
    margin-bottom: 1.5rem;

    &.bold {
        font-weight: 700;
    }

    // exceptions
    &.dayplan-title{
        font-size: rem-calc(30);
        font-weight: 400;
        margin-bottom: 1rem;

        strong{
            font-weight: 500;
        }

        &.bold {
            font-weight: 700;
        }
    }
}

h2, .heading-2 {
    color: $font-color;
    font-size: rem-calc(24);
    font-weight: 500;
    margin-bottom: 1rem;

    &.bold {
        font-weight: 700;
    }

    // exceptions
    &.dish-name{
        font-size: 30px;
        margin-bottom: 2rem;
        font-weight: 700;
    }
}

h3, .heading-3 {
    color: $font-color;
    font-size: rem-calc(22);
    font-weight: 500;
    margin-bottom: 1rem;

    &.bold {
        font-weight: 700;
    }

    // exceptions
    &.dish-name{
        font-size: 30px;
        margin-bottom: 2rem;
        font-weight: 700;
    }
}

h3, .heading-3 {
    color: $font-color;
    font-size: rem-calc(22);
    font-weight: 500;
    margin-bottom: 1rem;

    &.bold {
        font-weight: 700;
    }
}

h4, .heading-4 {
    font-size: 20px;
    font-weight: normal;

    &.pink{
        color: $pink;
    }
}

h5 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: block;
    width: 100%;
}

h4, .heading-4 {
    font-size: 20px;
    font-weight: normal;

    &.pink{
        color: $pink;
    }
}

h5 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: block;
    width: 100%;
}

p {
    color: $grey;
    font-size: rem-calc(18);
    font-weight: 400;
    margin: 0 0 2rem 0;
    line-height: 1.5;

    &.list-content {
        font-size: rem-calc(16);
    }

    &.semi-bold{
        font-weight: 500;
    }

    &.bold{
        font-weight: 700;
    }

    &.pink{
        color: $pink;
    }

    &.blue {
        color: $blue;
    }

    &.grey {
        color: $grey;
    }

    &.black {
        color: $black;
    }

    &.navy{
        color: $navy-blue;
    }

    &.paragraph-header{
        text-align: center;
        color: $black !important;
    }
}

strong{
    font-weight: 500;
}

span {
    &.blue {
        color: $blue;
    }

    &.grey {
        color: $grey;
    }

    &.black {
        color: $black;
    }

    &.pink{
        color: $pink;
    }

    &.bright-blue {
        color: $bright-blue;
    }

    &.pill{
        display: inline-block;
        border: 1px solid $light-grey;
        padding: 2px 10px;
        border-radius: $border-radius;
        color: $font-color;
        font-weight: 500;
        margin: 0 5px;
        font-size: 16px;
    }
}

a {
    word-wrap: break-word;
    color: $pink;

    &.grey {
        color: $grey;
    }

    &.pill{
        display: inline-block;
        border: 1px solid $light-grey;
        padding: 2px 10px;
        border-radius: $border-radius;
        color: $font-color;
        font-weight: 500;
        margin: 0 5px;
        font-size: 16px;
    }
}

ul.activities-list{
    font-size: 16px;
    list-style: none;
    
    padding: 0;
    margin: 0;

    li{
        position: relative;
        margin: 0 0 16px 0;
        padding-left: 16px;
        font-size: 16px;
        font-weight: 400;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            width: 6px;
            height: 6px;
            background: $pink;
            border-radius: 50%;
        }

        &:last-child{
            margin: 0;
        }
    }
}

ul.list {
    font-size: rem-calc(18);
    padding: 0;
    list-style: none;

    li {
        position: relative;
        padding: 0 0 0 1rem;
        color: $grey;

        &:before {
            content: '';
            position: absolute;
            top: 0.8rem;
            left: 0;
            width: 0.3rem;
            height: 0.3rem;
            background-color: $blue;
            border-radius: 50%;
        }
    }

    &.grey-dots {
        li {
            &:before {
                background-color: $grey;
            }
        }
    }
}

.faq-section {
    padding: 1rem 0 2rem 0;

    .collapse-item {
        border-bottom: 1px solid $light-grey;

        &:last-child {
            border-bottom: none;
        }
    }

    h3 {
        font-size: rem-calc(20);
        margin: 1rem 0;
        padding-right: 2rem;
        position: relative;

        button{
            background: none;
            border: none;
            color: $navy-blue;
            text-align: left;
            display: block;
            width: 100%;
            outline: none;
            padding: 0;
        }

        span.collapse-arrow {
            position: absolute;
            right: 0;
            top: 0;
            color: $pink;
            font-size: rem-calc(20);
            transition: all 0.3s ease-in-out;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .card {
        border: none;

        .card-body {
            padding: 0 0 1rem 0;
            text-align: left;
            font-size: rem-calc(18);
            color: $grey;

            p{
                margin: 0 0 1rem 0;
            }
        }
    }
}

blockquote{
    color: $navy-blue;
    font-size: rem-calc(18);
    font-weight: 400;
    border-left: 1px solid $blue;
    padding: 0 0 0 3rem;
    position: relative;

    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0.5rem;
        background-image: url('../assets/icons/guote-mark.svg');
        width: 22px;
        height: 22px;
    }
}
